<template>
	<div>
		<!-- 首页固定区 -->
		<div class="topadimage"> 
			<img :src="adList[0].adv_image" />
		</div>
		<div class="indexsearch">
		<div class="in-sousuo">
			<div class="sousuo-box">
				
			<el-dropdown @command="handleCommand" trigger="click">
				<span class="el-dropdown-link">
					{{ searchTypeText }}
					<i class="el-icon-arrow-down"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="goods">商品</el-dropdown-item>
					<el-dropdown-item command="shop">店铺</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" maxlength="50" />
			<el-button class="serbtn" type="primary" size="small" @click="search">搜索</el-button>
				 
			</div>
			<div class="hot-search-words" v-if="hotSearchWords" >
				 <ul>
					<li v-for="(item, index) in hotSearchWords" :key="index" @click="$router.push({ path: '/list', query: { keyword: item, search_type: 'goods' } })">
						{{ item }}
					</li>
				</ul>
				 
			</div>
		</div>
		</div>
		
		<!-- 热门咨询 行业信息 -->
		<div class="index_news">
			<div class="index_news_inner">
				<div class="hot_news">
					<div class="ht_title">
						<div class="httleft">
							平台介绍
						</div>
						<div class="httright">
							<router-link  to="/cms/help/listother-5">
							查看更多 >>
							</router-link>
						</div>
					</div>
					<ul class="ht_list">
						<li v-for="item in hotNewsList" :key="item.id">
							<div class="titleimgleft">
								<img :src="$img(item.titleimg)"/>
							</div>
							<div class="titleconright">
								<div class="tcrtitle">
								 <router-link :to="'/cms/help-' + item.id" >
								   {{item.title}}
								 </router-link>
								</div>
								<div class="tcrcon">{{item.content}}</div>
								<div class="tcrtime">{{item.create_time}}</div>
							</div>
							
						</li>
					</ul>
				</div>
				<div class="indu_news">
					<div class="topimg">
						<img src="../../assets/images/index/index_innews_bg.png"/>
					</div>
					<ul>
						<li v-for="item in induNewsList" :key="item.id">
							<router-link  :to="'/cms/help-' + item.id">
							{{item.title}}
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- 明星店铺 -->
		<div class="indexbigimg">
			<div class="ibiinner"> 
			   <div class="bimgtitle">明星店铺</div>
			   <div class="bimgimgs" v-if="0">
				   <div class="biileft" v-for="(alitem,alindex) in adLeftList" :key="alitem.adv_id" v-if="alindex<1" @click="openbigimg(alitem.adv_image_big)">
					   <img :src="$img(alitem.adv_image)"/><br />{{alitem.adv_title}}
				   </div>
				   <div class="biiright">
					   <ul>
					       <li v-for="(aritem,arindex) in adRightList" :key="aritem.adv_id" v-if="arindex>=2"  @click="openbigimg(aritem.adv_image_big)"><img :src="$img(aritem.adv_image)"/><br />{{aritem.adv_title}}</li> 
					   </ul>
					   <ul>
						   <li v-for="(aritem,arindex) in adRightList" :key="aritem.adv_id" v-if="arindex<2"  @click="openbigimg(aritem.adv_image_big)"><img :src="$img(aritem.adv_image)"/><br />{{aritem.adv_title}}</li>
						    
					   </ul>
					   
				   </div>
			   </div>
			</div>
		</div>
		 

		<div class="content">
			 

			<!-- 广告 -->
			<div class="content-div" v-if="adLeftList.length > 0 || adRightList.length > 0">
				<div class="ad-wrap">
					<div class="ad-big">
						<div class="ad-big-img" v-for="(item, index) in adLeftList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)" @click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
					<div class="ad-small">
						<div class="ad-small-img" v-for="(item, index) in adRightList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError(index)" @click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
				</div>
			</div>
 
		 
			</div>
		
		
		<div class="index-wrap2"   style="{ background: backgroundColor }" v-if="adList.length>0">
			
			<div class="index">
				<div class="banner">
					<el-carousel height="400px" arrow="hover" v-loading="loadingAd" @change="handleChange" ref="carousel">
						<el-carousel-item v-for="(item,index) in adList" :key="item.adv_id" v-if="index>0">
							<el-image :src="item.adv_image" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div> 
			   <div class="mcbox" >
			            
			            <div   v-for="(item,index) in adList" :key="item.adv_id"  v-if="index>0" :class="curcourseindex==index-1?'mcciboxactive':'mccibox'" @click="change_sj_desc(index-1)">
			               
			              <p class="mccit1">
			                 {{item.title1}}
			              </p>
						  <p class="mccit2">
						     {{item.title2}}
						  </p>
			     </div>       
			  </div>
					
					
					
		</div>
		
		<!-- 帮助中心 -->
		<div class="helplist">
			<div class="helpinner">
				<div class="helpleft">
					<div class="helpclass" v-for="(item, index) in helpList" :key="index" >
					    <p :class="index==helpclassindex?'helpclasscur':''" @click="changeHelpClass(index)">{{ item.class_name }}</p> 
					</div>
					<ul class="helpnews">
						<block v-for="(helpItem, helpIndex) in helpcurlist.child_list" :key="helpIndex" >
							<li  v-if="!helpItem.link_address"  @click="clickToHelpDetail(helpItem.id)">{{ helpItem.title }}<span>详情 >&nbsp;</span></li>
							<li  v-else  @click="clickJump(helpItem.link_address)">{{ helpItem.title }}<span>详情 >&nbsp;</span></li>
						</block>                      						
					</ul>
				</div>
				<div class="helpright">
					<div class="hlrtitle">热门商品</div>
					<div class="hlrimgs">
						<ul>
							
							<li v-for="(abitem,abindex) in regoodslist" :key="abitem.goods_id"   @click="opentardeurl(abitem.sku_id)"><img :src="$img(abitem.sku_image)"/></li>
							
							 
						</ul>
					</div>
				 
				</div>
			</div>
		</div>
			
			 
		</div>
	</div>
</template>

<script>
	import floorStyle1 from './components/floor-style-1.vue';
	import floorStyle2 from './components/floor-style-2.vue';
	import floorStyle3 from './components/floor-style-3.vue';
	import index from './_index/index.js';

	export default {
		name: 'index',
		components: {
			floorStyle1,
			floorStyle2,
			floorStyle3
		},
		mixins: [index]
	};
</script>

<style lang="scss" scoped>
	@import './_index/index.scss';
</style>

<style lang="scss" scoped>
	.count-down {
		span {
			display: inline-block;
			width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			background: #383838;
			color: #ffffff;
			border-radius: 2px;
		}
	}
	.more{
		width: 1200px;
		margin: auto;
		display: flex;
		justify-content: flex-end;
		a{
			color:#ff547b
		}
	}
	.goods-info {
		width:1200px;
	  margin:5px auto 40px;
	  display: flex;
	  flex-wrap: wrap;
	  .item {
	    width: 202px;
	    margin: 10px 20px 0 0;
	    border: 1px solid #eeeeee;
	    padding: 10px;
	    position: relative;
	    &:nth-child(5 n) {
	      margin-right: initial !important;
	    }
	    &:hover {
	      border: 1px solid $base-color;
	    }
	    .img-wrap {
	      width: 198px;
	      height: 198px;
	      cursor: pointer;
	    }
	    .goods-name {
	      overflow: hidden;
	      text-overflow: ellipsis;
	      white-space: nowrap;
	      cursor: pointer;
	      &:hover {
	        color: $base-color;
	      }
	    }
	    .price-wrap {
	      display: flex;
	      align-items: center;
	      .price {
	        display: flex;
	        color: $base-color;
	        font-size: $ns-font-size-lg;
	        p {
	          font-size: $ns-font-size-base;
	          display: flex;
	          align-items: flex-end;
	        }
	      }
	      .market-price {
	        color: #838383;
	        text-decoration: line-through;
	        margin-left: 10px;
	      }
	    }
	    .sale-num {
	      display: flex;
	      color: #838383;
	      p {
	        color: #4759a8;
	      }
	    }
	    .saling {
	      display: flex;
	      font-size: $ns-font-size-sm;
	      line-height: 1;
	      .free-shipping {
	        background: $base-color;
	        color: #ffffff;
	        padding: 3px 4px;
	        border-radius: 2px;
	        margin-right: 5px;
	      }
	      .promotion-type {
	        color: $base-color;
	        border: 1px solid $base-color;
	        display: flex;
	        align-items: center;
	        padding: 1px;
	      }
	    }
	  }
	}
	.in-sousuo {
			width: 700px;
			padding-top: 20px;
			margin:auto; 
		 
			.sousuo-box {
				width: 100%;
				height: 46px;
				line-height:46px;
				border: 1px solid #61433c;
				box-sizing: border-box;
		        border-radius: 10px;
				.el-dropdown {
					padding: 0 10px;
					cursor: pointer;
		
					&::after {
						content: '';
						border-left: 1px solid #cfcfcf;
						margin-left: 5px;
					}
				}
		        .serbtn{
					background-color: #61433c;
					border-radius: 10px;
				    border: 3px solid #61433c;
					width:80px;
					height:45px;
					font-size:15px;
				}
				input {
					width: 380px;
					height: 22px;
					background: none;
					outline: none;
					border: none;
					margin: 4px;
				}
		
				button {
					border-radius: 0;
					float: right;
				}
			}
		
			.hot-search-words {
				width: 100%;
				height: 20px;
				margin-top: 10px;
				font-size: 14px;
		 
		
				ul {
					overflow: hidden;
					margin: 0;
					height: 21px;
					padding: 0;
					color: #111111; 
		            padding-left:80px; 
					li {
						cursor: pointer;
						list-style: none;
						float: left;
						margin-right: 15px;
		
						&:hover {
							color: $base-color;
						}
					}
				}
			}
		}
		.indexsearch{
			width: 100%;
			background-color: #ffffff !important;
			padding-top:10px;
		}
		.indexbigimg{
			width: 100%;
			margin-top:50px;
			margin-bottom:30px; 
			float:left;
			.ibiinner{
				width: $width;
				margin:0 auto;
				.bimgtitle{
					height:60px;
					text-align:center;
					background: url(../../assets/images/index/hot_news_bt_bg.png) no-repeat center bottom;
					background-size: 12%;
					color:#142c70;
					font-size:25px;
					font-weight: bold;
				}
				.bimgimgs{
					margin-top:30px;
					text-align:center;
					font-weight:bold;
					font-size:20px;  
					.biileft{
						width:30%;
						float:left; 
						cursor:pointer;
					}
					.biiright{
						width:70%; 
						float:left; 
						 
						li{ 
							width:50%;
							display:block;
							float:left;  
							cursor:pointer;
							img{
								width:90%;
							}
						}
					}
				}
			}
		}
		.mcbox{
			position: absolute;
			z-index: 999;
			height:80px;
			width:940px;
			padding-left:270px;
			background-color: #e1e7e9;
			opacity: 0.7;
			 
			top:2095px; 
			text-align:center;
		}
		.mccibox{
			display:block;
			float:left;
			width:220px;
			height:80px;
			cursor: pointer;
			background-color: #f0f0f0;
			text-align:center;
			 color:#000000;
			  
			.mccit1{
				 margin-top:15px;
			     font-weight: bolder;
				 font-size:15px; 
			}
			.mccit2{
				
			}
			
		}
		.mcciboxactive{
			display:block;
			float:left;
			width:220px;
			height:80px;
			cursor: pointer;
			background-color: #ffffff;
			text-align:center;
			color:#063bc6;
			.mccit1{
				margin-top:15px;
			     font-weight: bolder;
				 font-size:15px; 
			}
			.mccit2{
				
			}
			
		}
</style>

